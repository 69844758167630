@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./assets/jetbrains-mono.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Inter", sans-serif;
}

code, code * {
    font-family: "JetBrains Mono", monospace;
}
